<template>
  <b-nav-item-dropdown class="dropdown-cart mr-25" menu-class="dropdown-menu-media" right @show="getCartInfo">
    <template #button-content>
      <feather-icon :badge="info.totalQty" class="text-body" icon="ShoppingCartIcon" size="21"/>
    </template>
    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Моя корзина</h4>
        <b-badge pill variant="light-primary">{{ info.totalQty }} шт</b-badge>
      </div>
    </li>
    <!-- Cart Items -->
    <vue-perfect-scrollbar
        v-if="info.items"
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
    >
      <b-media no-body v-for="item in info.items" :key="item.id">

        <b-media-aside vertical-align="center">
          <img
              class="rounded"
              :src="`/img/product/${item.small_main_image}`"
              :alt="item.product_specification.product.name"
              width="62px"
          >
        </b-media-aside>
        <b-media-body>
          <div class="row">
            <div class="col-12">
              <h4 class="font-small-3 mb-0">
                {{ item.product_specification.product.name }} {{ item.product_specification.product.brand_name }}
              </h4>
            </div>
            <div class="col-12">
              <small>Мерки: {{ item.product_specification.full_measurements }}</small>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col align-self-center">
              <small>Размер: <span class="text-uppercase">{{ item.product_specification.size_name }}</span></small>
            </div>
            <div class="col">
              <b-form-spinbutton v-model="item.qty" min="1" size="sm" @change="changeQty(item.id, item.qty)"/>
            </div>
            <div class="col align-self-center">
              <h5 class="mb-0">{{ item.cost }} грн</h5>
            </div>
          </div>
        </b-media-body>
        <feather-icon icon="XIcon" class="cart-item-remove cursor-pointer" @click.stop="removeFromCart(item.id)"/>
      </b-media>
    </vue-perfect-scrollbar>
    <!-- Cart Footer -->
    <li v-if="info.items" class="dropdown-menu-footer">
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">Итого:</h6>
        <h6 class="text-primary font-weight-bolder mb-0">{{ info.totalAmount }} грн</h6>
      </div>
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block :to="{ name: 'checkout' }">
        Оформить заказ
      </b-button>
    </li>
    <p v-if="!info.items" class="m-0 p-1 text-center">Ваша корзина пустая</p>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BMediaAside, BMediaBody, BFormSpinbutton, BButton,
} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormSpinbutton,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      info: {
        totalAmount: 0,
        totalQty: 0,
        items: [],
      },
      loading: true,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    };
  },
  async mounted() {
    await this.getCartInfo();
  },
  methods: {
    async changeQty(orderItemId, qty) {
      try {
        await this.$api.cart.changeQty(orderItemId, { qty });
      } catch (e) {
        console.log(e);
      }
    },
    async getCartInfo() {
      this.loading = true;
      this.info = (await this.$api.cart.getInfo()).data;
      this.loading = false;
    },
    async removeFromCart(orderItemId) {
      try {
        this.$api.cart.removeItem(orderItemId)
            .then(async () => {
              const itemIndex = this.info.items.findIndex((p) => p.id === orderItemId);
              this.info.items.splice(itemIndex, 1);
              await this.getCartInfo();
            });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
