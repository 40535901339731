export default [
    {
        title: 'Аналитика',
        route: 'dashboard',
        icon: 'TrendingUpIcon',
    },
    {
        title: 'Товары в наличии',
        route: 'remains',
        icon: 'TagIcon',
    },
    {
        title: 'Корзина',
        route: 'checkout',
        icon: 'ShoppingCartIcon',
    },
    {
        title: 'Заказы',
        route: 'order-list',
        icon: 'FileTextIcon',
    },
    {
        title: 'Возвраты',
        route: 'returns',
        icon: 'CornerDownLeftIcon',
        action: 'read',
        resource: 'returns',
    },
    {
        title: 'Оплаты',
        route: 'payment-list',
        icon: 'DollarSignIcon',
    },
    {
        title: 'Автоматизация',
        icon: 'DatabaseIcon',
        children: [
            {
                title: 'Rozetka',
                route: 'rozetka-configuration',
            },
            {
                title: 'Kidstaff',
                route: 'kidstaff-configuration',
            },
        ],
    },
    {
        title: 'Товары',
        icon: 'ShoppingBagIcon',
        route: 'products',
    },
    {
        title: 'Мой склад',
        icon: 'ArchiveIcon',
        route: 'stocks',
    },
];
